import axios from 'axios';

const API = axios.create({ baseURL: process.env.NEXT_PUBLIC_API_URL });

API.interceptors.request.use((req) => {
  let token = JSON.parse(localStorage.getItem("login_access"));
  if (token?.access_token) {
    req.headers.Authorization = `Bearer ${token?.access_token}`;
  }
  return req;
});

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (typeof window !== 'undefined' && error.response?.status == 401) {
      localStorage.clear();
      window.location.href = window.location.origin;
    }
    return Promise.reject(error);
  }
);


export default API;

