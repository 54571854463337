import { useState, useEffect } from "react";
import Link from "next/link";
import { Container, Row, Col } from "react-bootstrap";
import {
  IoIosPhonePortrait,
  IoIosMailOpen,
  IoIosArrowUp,
} from "react-icons/io";

import { animateScroll } from "react-scroll";
import { isMobile } from "react-device-detect";
import { toast } from "react-hot-toast";
import { connect } from "react-redux";
import { FaWhatsapp } from "react-icons/fa";
import API from "api";

const Footer = ({ hideScrollToTop = false, globalData }) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);
  const [phone, setPhone] = useState("");
  const header = { fontSize: 24, fontWeight: "700", color: "#0451be" };
  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  const subscribeFun = () => {
    let bodyData = {
      SubmitBy: "Web",
      PhoneNumber: phone,
    };
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (phone != "") {
      API.post(`v2/api/Subscribes`, bodyData, {
        headers: header,
      })
        .then(() => {
          toast.dismiss();
          toast.success("Subscription Successful!");
          setPhone("");
          // alert(JSON.stringify(resp))
          // if (resp.Data.Id) {
          //   alert("Subscribed Successfully");
          // } else {
          //   alert("something went wrong...");
          // }
        })
        .catch((error) => {
          console.error(error);
          alert(error.message);
        });
    } else {
      alert("please enter phone address");
    }
  };
  return (
    <footer className="footer-dark" style={{ marginTop: "150px", zIndex: 3 }}>
      <div className="footer-top">
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <div
                className="widget"
                style={{ marginTop: isMobile ? "10%" : "0%" }}
              >
                <div className="widget-title foot-head" style={header}>
                  Open Bonnet
                </div>
                <p style={{ fontSize: "15px", fontWeight: "400" }}>
                  Open Bonnet is a Marketplace Where Car Owners Are Connected
                  With Verified Service Providers Through A Fully Digitized
                  Experience Offering Convenience, Quality & Trust!
                </p>
                <ul className="contact-info contact-info-light mt-3">
                  {/* <li>
                    <IoIosPin style={{ fontSize: 18 }} />
                    <a href="https://www.google.com/search?q=open%20bonnet&sxsrf=ALiCzsYtYnDQWB3xEnW9m7Q2jCzVz5crzw:1660641218649&source=hp&ei=t1_7YtTDEIi-aciClsgJ&iflsig=AJiK0e8AAAAAYvttx_WqNXKukostwXX57Ib_py0XOPqM&oq=openbonn&gs_lcp=Cgdnd3Mtd2l6EAMYATIHCCMQsQIQJzIHCCMQsQIQJzIKCC4QxwEQrwEQCjIECAAQCjIECAAQCjIECAAQCjIECAAQCjIECAAQCjIECAAQCjIECAAQCjoHCCMQ6gIQJzoECCMQJzoKCC4QxwEQrwEQJzoLCAAQgAQQsQMQgwE6CAgAEIAEELEDOgQIABBDOg0ILhCxAxDHARDRAxBDOg4IABCABBCxAxCDARDJAzoFCAAQkgM6BQgAEIAEOgsILhCABBDHARDRAzoNCC4QgAQQxwEQrwEQCjoHCAAQgAQQClDCBlieI2C6LGgBcAB4AIABlwKIAfgPkgEDMi04mAEAoAEBsAEK&sclient=gws-wiz&tbs=lrf:!1m4!1u2!2m2!2m1!1e1!1m4!1u3!2m2!3m1!1e1!2m1!1e2!2m1!1e3!3sCgIIASAB,lf:1,lf_ui:10&tbm=lcl&rflfq=1&num=10&rldimm=13160443980072066063&lqi=CgtvcGVuIGJvbm5ldCICGAFI_vnfw_2vgIAIWgwQABgAIgZib25uZXSSAQpjYXJfcmVwYWlymgEkQ2hkRFNVaE5NRzluUzBWSlEwRm5TVU5OTW1OMWJYWkJSUkFCqgEOEAEqCiIGYm9ubmV0KAA&phdesc=h9TqiOfUp_o&ved=2ahUKEwiD4r7Pgsv5AhUwYPEDHYXNBdoQvS56BAhMEAE&sa=X&rlst=f&rlvp=clear#rlfi=hd:;si:13160443980072066063,l,CgtvcGVuIGJvbm5ldCICGAFI_vnfw_2vgIAIWgwQABgAIgZib25uZXSSAQpjYXJfcmVwYWlymgEkQ2hkRFNVaE5NRzluUzBWSlEwRm5TVU5OTW1OMWJYWkJSUkFCqgEOEAEqCiIGYm9ubmV0KAA,y,h9TqiOfUp_o;mv:[[25.122063177319028,55.3777510806316],[25.121703222680974,55.3773535193684]];tbs:lrf:!1m4!1u2!2m2!2m1!1e1!1m4!1u3!2m2!3m1!1e1!2m1!1e2!2m1!1e3!3sCgIIASAB,lf:1,lf_ui:10&rlvp=clear">
                      OB TECH FZCO, Dubai Silicon Oasis, Dubai, UAE
                    </a>
                  </li> */}
                  <li>
                    <IoIosPhonePortrait style={{ fontSize: 18 }} />
                    <a href="tel:+9718002274357">800-CARHELP (8002274357) </a>
                  </li>
                  <li>
                    <FaWhatsapp style={{ fontSize: 18 }} />
                    <a
                      href="https://api.whatsapp.com/send?phone=9718002274357"
                      target="_blank"
                    >
                      WhatsApp us
                    </a>
                  </li>
                  <li>
                    <IoIosMailOpen style={{ fontSize: 18 }} />
                    <a href="mailto:user@openbonnet.com">user@openbonnet.com</a>
                  </li>
                </ul>
              </div>
              <div className="widget">
                <ul className="social-icons mb-3 mb-lg-0">
                  <li>
                    <Link href="https://www.facebook.com/openbonnetdotcom/">
                      <a className="" target="_blank">
                        <img src="/assets/footer/facebook.svg" alt="facebook" />
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="https://www.instagram.com/open_bonnet/">
                      <a className="" target="_blank">
                        <img
                          src="/assets/footer/instagram.svg"
                          alt="instagram"
                        />
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="https://www.twitter.com/open_bonnet">
                      <a className="" target="_blank">
                        <img src="/assets/footer/twitter.svg" alt="twitter" />
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="https://www.linkedin.com/company/openbonnet/">
                      <a className="" target="_blank">
                        <img src="/assets/footer/linkedin.svg" alt="linkd in" />
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="https://www.youtube.com/channel/UCMw6hZugnW7DxCBDg2ZmKdg/videos">
                      <a className="" target="_blank">
                        <img src="/assets/footer/youtube.svg" alt="youtube" />
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="https://www.tiktok.com/@open_bonnet">
                      <a className="" target="_blank">
                        <img
                          style={{ width: "25px", height: "25px" }}
                          src="/assets/footer/tiktok-icon.svg"
                          alt="tiktok"
                        />
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="https://www.snapchat.com/add/open_bonnet">
                      <a className="" target="_blank">
                        <img
                          style={{ width: "25px", height: "25px" }}
                          src="/assets/footer/snapchat-icon.svg"
                          alt="snapchat"
                        />
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="widget">
                <ul className="footer-payment">
                  <li className="pr-2">
                    <a href="javascript:void(0)">
                      <img src="/assets/footer/visa.svg" alt="visa" />
                    </a>
                  </li>

                  <li className="pr-2">
                    <a href="javascript:void(0)">
                      <img
                        src="/assets/footer/mastercard.webp"
                        width="60px"
                        alt="master_card"
                      />
                    </a>
                  </li>
                  <li className="pr-2">
                    <a href="javascript:void(0)">
                      <img src="/assets/footer/payit.svg" alt="payit" />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={3} md={3} sm={6}>
              <div className="widget">
                <div className="widget-title foot-head" style={header}>
                  Links
                </div>
                <ul className="widget-links">
                  <li>
                    <Link href="/about-us">
                      <a className="foot-text">About Us</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="https://blog.openbonnet.com/blog">
                      <a className="foot-text">Our Blogs</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/faq">
                      <a className="foot-text">FAQs</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/workshop-marketing">
                      <a className="foot-text">For Service Providers</a>
                    </Link>
                  </li>
                  {/* <li>
                    <Link href="">
                      <a className="foot-text">Service Date Calculator</a>
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link href="/blog/grid-standard-right-sidebar">
                      <a className="foot-text">Blogs</a>
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link href="">
                      <a className="foot-text">Tools & Calculators</a>
                    </Link>
                  </li> */}
                  <li>
                    <Link href="/privacy-policy">
                      <a className="foot-text">Privacy Policy</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/terms-and-conditions">
                      <a className="foot-text">Service Terms</a>
                    </Link>
                  </li>
                  {/* <li>
                    <Link href="/fleet-service">
                      <a className="foot-text">Fleet Servicing Solution</a>
                    </Link>
                  </li> */}
                  <li>
                    <Link href="/super-car">
                      <a className="foot-text">Supercar Services</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/sign-up">
                      <a className="foot-text">User Sign Up</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={3} md={3} sm={6}>
              <div className="widget">
                <div className="widget-title foot-head" style={header}>
                  Services
                </div>
                <ul className="widget-links">
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/oil-change`}
                    >
                      <a className="foot-text">Oil Change Periodic Services</a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/repair`}
                    >
                      <a className="foot-text">Maintenance & Repair</a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/car-care`}
                    >
                      <a className="foot-text">Car Care & Cleaning</a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/tires-wheels`}
                    >
                      <a className="foot-text">Tyres & Wheels</a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/battery`}
                    >
                      <a className="foot-text">Battery Assistance</a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/inspection`}
                    >
                      <a className="foot-text">Pre-Purchase Inspection</a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/ac-service`}
                    >
                      <a className="foot-text">Air Conditioning Services</a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/mobile-mechanic`}
                    >
                      <a className="foot-text">At-Home Services</a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/breakdown`}
                    >
                      <a className="foot-text">Car Breakdown</a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/car-wash-at-home`}
                    >
                      <a className="foot-text">Car Wash at Home</a>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/services/${
                        globalData.locationData?.data?.EmirateSlug || "dubai"
                      }/service-contract`}
                    >
                      <a className="foot-text">Service Contract</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="text-center"
        style={{ paddingLeft: "4%", paddingRight: "4%" }}
      >
        <Container
          fluid
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row className="footer-subscribe">
            <Col
              lg={6}
              md={6}
              sm={6}
              className="p-4 d-flex align-items-center hide-in-mobile"
            >
              <img src="/assets/whatsapp_new.svg" alt="whatsapp" />
              <div className="ml-3" style={{ textAlign: "left" }}>
                <div style={{ fontSize: 22, fontWeight: "600", color: "#fff" }}>
                  Subscribe to our WhatsApp Channel
                </div>
                <div style={{ fontSize: 12, fontWeight: "400", color: "#fff" }}>
                  Stay connected with OB & get alerts of best offers for your
                  car.
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} sm={6} className="p-4 for-mobile">
              <div style={{ fontSize: 22, fontWeight: "600", color: "#fff" }}>
                Subscribe to our WhatsApp Channel
              </div>
              <div
                className="mt-3 d-flex align-items-center"
                style={{ textAlign: "left" }}
              >
                <img src="/assets/whatsapp_new.svg" alt="whatsapp" />

                <div
                  className="ml-3"
                  style={{ fontSize: 12, fontWeight: "400", color: "#fff" }}
                >
                  Stay connected with OB & get alerts of best offers for your
                  car.
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} sm={6} className="p-4">
              <div className="footer-input">
                <div className="ml-2 d-flex align-items-center">
                  <svg
                    width={31}
                    height={30}
                    viewBox="0 0 31 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.0083 29.8114C23.2886 29.8114 30.0011 23.1539 30.0011 14.9414C30.0011 6.72885 23.2886 0.0712891 15.0083 0.0712891C6.72809 0.0712891 0.015625 6.72885 0.015625 14.9414C0.015625 23.1539 6.72809 29.8114 15.0083 29.8114Z"
                      fill="#F0F0F0"
                    />
                    <path
                      d="M8.55469 20.2178L9.86998 29.0672C13.6317 30.4476 17.792 30.2911 21.4374 28.6319C25.0828 26.9727 27.9154 23.9465 29.3132 20.2178H8.55469Z"
                      fill="black"
                    />
                    <path
                      d="M8.55469 9.78162L9.86998 0.932157C13.6314 -0.447474 17.791 -0.290494 21.4359 1.36864C25.0807 3.02778 27.9129 6.05349 29.3107 9.78162H8.55469Z"
                      fill="#6DA544"
                    />
                    <path
                      d="M8.06025e-10 14.9987C-3.18685e-05 18.0616 0.944996 21.0512 2.70814 23.5658C4.47128 26.0805 6.96809 27.9997 9.86317 29.0658V0.932129C6.96817 1.99817 4.47142 3.91736 2.70829 6.4319C0.945148 8.94643 7.18711e-05 11.9359 8.06027e-10 14.9987H8.06025e-10Z"
                      fill="#A2001D"
                    />
                  </svg>
                  +971
                </div>
                <input
                  type="number"
                  style={{ border: "none", margin: "17px", width: "100%" }}
                  placeholder={"Enter your Number..."}
                  value={phone}
                  onChange={(e) => {
                    if (e.target.value.length === 1 && e.target.value != 5) {
                      toast.dismiss();
                      toast.error("Enter valid number!");
                      return;
                    }
                    if (e.target.value.length < 10) {
                      setPhone(e.target.value);
                    }
                  }}
                />
              </div>
            </Col>
            <Col lg={2} md={2} sm={6} className="p-4">
              <button
                onClick={subscribeFun}
                className="footer-button-subscribe"
              >
                Subscribe
              </button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bottom-footer text-center">
        <Container>
          <Row>
            <Col md={12} lg={6}>
              <p className="mb-3 mb-md-0 text-center greyC">
                Using this website you agree to our{" "}
                <Link href="/terms-and-conditions">
                  <a>Terms & Conditions</a>
                </Link>{" "}
                and{" "}
                <Link href="/privacy-policy">
                  <a>Privacy Policy</a>
                </Link>
                .
              </p>
            </Col>
            <Col md={12} lg={6}>
              <p className="mb-3 mb-md-0 text-center greyC">
                &copy; 2023 - 2024 All rights reserved by{" "}
                <a
                  href="https://www.openbonnet.com"
                  className="blueC"
                  target="_blank"
                >
                  Open Bonnet
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <img src="/assets/images/Ob_footer_logo.webp" className="footLogo" />
      {!hideScrollToTop && (
        <button
          className={`scroll-top ${scroll > top ? "show" : ""}`}
          onClick={() => scrollToTop()}
        >
          <IoIosArrowUp />
        </button>
      )}
    </footer>
  );
};

const mapStateToProps = (state) => {
  return {
    globalData: state.globalData,
    apiData: state.apiData,
    loginData: state.loginData,
    profile: state.profile,
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

// <Col>
// <div className="footer-subscribe-mobile">
//     <div className="footer-input-mobile">
//         <input type="text" style={{border:'none',margin:'17px',width:'100%'}} placeholder={"Enter your phone..."}/>
//       </div>
//       <button className="footer-button-subscribe-mobile">Subscribe</button>
//   </div>
// </Col>
